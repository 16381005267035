

export default class AdminMiddleware 
{

    setJWTToCookie(accessToken)
    {
        var date = new Date();
        date.setTime(date.getTime() + (30*24*60*60*1000));
        var expires = "; expires=" + date.toUTCString();
        document.cookie = "AdminJWT=" + (accessToken || "")  + expires + "; path=/";
    }

    getJWTFromCookie()
    {
        var nameEQ = "AdminJWT=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    clearJWTCookie()
    {
        var date = new Date();
        date.setTime(date.getTime() - (1000));
        var expires = "; expires=" + date.toUTCString();
        document.cookie = "AdminJWT=" + expires + "; path=/";
        //this.clearAccessToMenuTabs();
        localStorage.clear();
    }

    /*
    updateAccessToMenuTabs(adminAccessViewModel)
    {
        localStorage.setItem("panel_admins", adminAccessViewModel.panel_admins);
        localStorage.setItem("panel_lessons", adminAccessViewModel.panel_lessons);
        localStorage.setItem("panel_users", adminAccessViewModel.panel_users);
        localStorage.setItem("panel_dance_groups", adminAccessViewModel.panel_dance_groups);
        localStorage.setItem("panel_teachers", adminAccessViewModel.panel_teachers);
        localStorage.setItem("panel_teacher_salaries", adminAccessViewModel.panel_teacher_salaries);
        localStorage.setItem("panel_abonements", adminAccessViewModel.panel_abonements);
        localStorage.setItem("panel_discounts", adminAccessViewModel.panel_discounts);
        localStorage.setItem("panel_cansels", adminAccessViewModel.panel_cansels);
        localStorage.setItem("panel_branches", adminAccessViewModel.panel_branches);
        localStorage.setItem("panel_cashbox", adminAccessViewModel.panel_cashbox);
        localStorage.setItem("panel_surveillance", adminAccessViewModel.panel_surveillance);
    }

    clearAccessToMenuTabs()
    {
        localStorage.removeItem("panel_admins");
        localStorage.removeItem("panel_lessons");
        localStorage.removeItem("panel_users");
        localStorage.removeItem("panel_dance_groups");
        localStorage.removeItem("panel_teachers");
        localStorage.removeItem("panel_teacher_salaries");
        localStorage.removeItem("panel_abonements");
        localStorage.removeItem("panel_discounts");
        localStorage.removeItem("panel_cansels");
        localStorage.removeItem("panel_branches");
        localStorage.removeItem("panel_cashbox");
        localStorage.removeItem("panel_surveillance");
    }
    */
}