//import imgStatementDefault from "../../assets/images/user.png";
import { Link } from "react-router-dom";
import imgAccept from "../../assets/images/icon-accept.png";
import imgCansel from "../../assets/images/icon-cansel.png";

function padTo2Digits(num) 
{
    return num.toString().padStart(2, '0');
}
  
function formatDate(date) 
{
    return (
        [
          padTo2Digits(date.getHours()),
          padTo2Digits(date.getMinutes()),
        ].join(':') + ' ' +
    [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear()
    ].join('.')
    );
}

//<img className="img-fluid accept" src={imgAccept} alt="accept" />
export default function StatementSearchPreview(props)
{
    if(props.statementPreviewViewModel === null)
    {
        return  <></>;
    }

    //const imgStatementSrc = (props.statementPreviewViewModel.photo_file_src !== null && typeof(props.statementPreviewViewModel.photo_file_src) !== "undefined" ? constant.baseDomain + "/" + props.statementPreviewViewModel.photo_file_src : imgStatementDefault);

    const statusLabel = (props.statementPreviewViewModel.status !== null && props.statementPreviewViewModel.status === 1 
        ? " - отправлено"
        : ""
    );

    const userMicroData = (props.statementPreviewViewModel.userMicroViewModel !== null 
        ? props.statementPreviewViewModel.userMicroViewModel.secondname + " " + props.statementPreviewViewModel.userMicroViewModel.firstname
        : "<Ошибка поиска пользователя>"    
    );

    const nominationMicroData = (props.statementPreviewViewModel.nominationMicroViewModel !== null
        ? props.statementPreviewViewModel.nominationMicroViewModel.name
        : "<Ошибка определения номинации>"
    );

    let dateOfUpdateString = "не заполнялось";
    if(props.statementPreviewViewModel.date_of_update !== null)
    {
        dateOfUpdateString = formatDate(new Date(props.statementPreviewViewModel.date_of_update));
    }

    let userExpertGradeCircles = "";
    if(props.statementPreviewViewModel.user_expert_statement_number !== null && props.statementPreviewViewModel.user_expert_statement_appreciated_number !== null)
    {
        let arrayOfIndexes = [];
        for(let i = 1; i <= props.statementPreviewViewModel.user_expert_statement_number; i++)
        {
            arrayOfIndexes.push(i);
        }
        userExpertGradeCircles = arrayOfIndexes.map(number => {
            return <img 
            key={number} 
            className="img-fluid user-expert-statement-grade-is-appreciated" 
            src={number <= props.statementPreviewViewModel.user_expert_statement_appreciated_number ? imgAccept : imgCansel} alt="user-expert-statement-grade-is-appreciated" 
            />
            
        })
    }

    return (
        <div className="col-12">
            <div className="statement-search-preview" 
            //onClick={() => props.getUserProfileById(props.userPreviewViewModel.id)}
            >
                <Link to={"/statement/" + props.statementPreviewViewModel.id} target="_blank">
                    <p>{userMicroData} <span>id{props.statementPreviewViewModel.id} {statusLabel}</span></p>
                    <ul className="short-info">
                        <li> Номинация: {nominationMicroData}</li>
                        <li> Последняя дата правки: {dateOfUpdateString}</li>
                        <li>{userExpertGradeCircles}</li>
                    </ul>
                </Link>
            </div>
        </div>
    )
}

//<img src={imgStatementSrc} className="img-fluid poster" alt="poster" loading="lazy" />