import { Link } from "react-router-dom";
import constant from "../../utils/GlobalVariables";
import UserExpertStatementGradePointBlock from "./UserExpertStatementGradePointBlock";

export default function UserExpertStatementGradeBlock(props)
{

    if(props.userStatementViewModel == null)
    {
        return <p>Нет модели заявления</p>;
    }
    if(props.statementPage == null)
    {
        return <p>Не найден номер страницы</p>;
    }
    
    if(props.userExpertStatementGradeEditViewModel === null)
    {
        return <p>Нет модели экспертизы</p>;
    }
    if(props.userExpertStatementGradeEditViewModel.userExpertMicroViewModel === null)
    {
        return <p>Нет модели эксперта</p>;
    }

    const userExpertStatementGradeEditViewModel = props.userExpertStatementGradeEditViewModel
    const userExpertMicroViewModel = props.userExpertStatementGradeEditViewModel.userExpertMicroViewModel;
    const userStatementViewModel = props.userStatementViewModel;
    const statementPage = props.statementPage;
    
    const formListener = (e) => {

    }

    /*
    let leagueParticipationMembershipStatusOptions = "";
    if(props.leagueParticipationMembershipStatusMicroViewModels !== null)
    {
        leagueParticipationMembershipStatusOptions = props.leagueParticipationMembershipStatusMicroViewModels.map((leagueParticipationMembershipStatusMicroViewModel, index) => {
            return <option 
            key={index} 
            value={leagueParticipationMembershipStatusMicroViewModel.id}>
                {leagueParticipationMembershipStatusMicroViewModel.name}
            </option>
        })
    }

    let regionMicroOptions = "";
    if(props.regionMicroViewModels !== null)
    {
        regionMicroOptions = props.regionMicroViewModels.map((regionMicroViewModel, index) => {
            return <option key={index} value={regionMicroViewModel.id}>{regionMicroViewModel.name}</option>
        });
    }

    let universityMicroOptions = "";
    if(props.universityMicroViewModels !== null)
    {
        universityMicroOptions = props.universityMicroViewModels.map((universityMicroViewModel, index) => {
            return <option key={index} value={universityMicroViewModel.id}>{universityMicroViewModel.name}</option>
        });
    }
    */
    
    let list_of_statement_applications_and_characteristics_files = "";
    let statement_applications_and_characteristics_file_index = 1;
    if(userStatementViewModel.userStatementApplicationsAndCharacteristicsFileViewModels !== null)
    {
        list_of_statement_applications_and_characteristics_files = <ul className="files-list">
            {userStatementViewModel.userStatementApplicationsAndCharacteristicsFileViewModels.map(userStatementApplicationsAndCharacteristicsFileViewModel => {
                return <li key={userStatementApplicationsAndCharacteristicsFileViewModel.index}>- <Link to={constant.baseDomain + "/" + userStatementApplicationsAndCharacteristicsFileViewModel.src} target="_blank">
                Файл №{statement_applications_and_characteristics_file_index++}</Link>
                </li>
            })}
        </ul>
    }

    let list_of_other_files = "";
    let other_file_number = 1;
    if(userStatementViewModel.userStatementOtherFileViewModels !== null)
    {
        list_of_other_files = <ul className="files-list">
            {userStatementViewModel.userStatementOtherFileViewModels.map(userStatementOtherFileViewModel => {
                return <li key={userStatementOtherFileViewModel.index}>- <Link to={constant.baseDomain + "/" + userStatementOtherFileViewModel.src} target="_blank">
                Файл №{other_file_number++}</Link>
                </li>;
            })}
        </ul>
    }

    return(
        
        <form className="statement-grade" encType="multipart/form-data">

            <div className={"statement-page " + (statementPage === 1 ? "active" : "")}>

                <p className="statement-page-header">Данные эксперта</p>
                <p><b>Логин эксперта:</b> {userExpertMicroViewModel.username}</p>
                <p><b>ФИО эксперта:</b> {userExpertMicroViewModel.secondname + " " + userExpertMicroViewModel.firstname}</p>

            </div>


            <div className={"statement-page " + (statementPage === 2 ? "active" : "")}>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Образование*</label>
                        <p>{userStatementViewModel.education} </p>
                    </div>
                    <UserExpertStatementGradePointBlock
                    nameOfValue="education"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.education}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="science_degree">Научная степень</label>
                        <select id="science_degree" className="form-control" defaultValue={userStatementViewModel.science_degree} disabled>
                            <option value="">- не выбрано</option>
                            <option value="Не имею">Не имею</option>
                            <option value="Кандидат архитектурных наук">Кандидат архитектурных наук</option>
                            <option value="Кандидат биологических наук">Кандидат биологических наук</option>
                            <option value="Кандидат ветеринарных наук">Кандидат ветеринарных наук</option>
                            <option value="Кандидат военных наук">Кандидат военных наук</option>
                            <option value="Кандидат географических наук">Кандидат географических наук</option>
                            <option value="Кандидат геолого-минералогических наук">Кандидат геолого-минералогических наук
                            </option>
                            <option value="Кандидат искусствоведческих наук">Кандидат искусствоведческих наук</option>
                            <option value="Кандидат исторических наук">Кандидат исторических наук</option>
                            <option value="Кандидат культурологических наук">Кандидат культурологических наук</option>
                            <option value="Кандидат медицинских наук">Кандидат медицинских наук</option>
                            <option value="Кандидат педагогических наук">Кандидат педагогических наук</option>
                            <option value="Кандидат политических наук">Кандидат политических наук</option>
                            <option value="Кандидат психологических наук">Кандидат психологических наук</option>
                            <option value="Кандидат сельскохозяйственных наук">Кандидат сельскохозяйственных наук</option>
                            <option value="Кандидат социологических наук">Кандидат социологических наук</option>
                            <option value="Кандидат теологических наук">Кандидат теологических наук</option>
                            <option value="Кандидат технических наук">Кандидат технических наук</option>
                            <option value="Кандидат фармацевтических наук">Кандидат фармацевтических наук</option>
                            <option value="Кандидат физико-математических наук">Кандидат физико-математических наук</option>
                            <option value="Кандидат филологических наук">Кандидат филологических наук</option>
                            <option value="Кандидат философских наук">Кандидат философских наук</option>
                            <option value="Кандидат химических наук">Кандидат химических наук</option>
                            <option value="Кандидат экономических наук">Кандидат экономических наук</option>
                            <option value="Кандидат юридических наук">Кандидат юридических наук</option>
                            <option value="Доктор архитектурных наук">Доктор архитектурных наук</option>
                            <option value="Доктор биологических наук">Доктор биологических наук</option>
                            <option value="Доктор ветеринарных наук">Доктор ветеринарных наук</option>
                            <option value="Доктор военных наук">Доктор военных наук</option>
                            <option value="Доктор географических наук">Доктор географических наук</option>
                            <option value="Доктор геолого-минералогических наук">Доктор геолого-минералогических наук</option>
                            <option value="Доктор искусствоведческих наук">Доктор искусствоведческих наук</option>
                            <option value="Доктор исторических наук">Доктор исторических наук</option>
                            <option value="Доктор культурологических наук">Доктор культурологических наук</option>
                            <option value="Доктор медицинских наук">Доктор медицинских наук</option>
                            <option value="Доктор педагогических наук">Доктор педагогических наук</option>
                            <option value="Доктор политических наук">Доктор политических наук</option>
                            <option value="Доктор психологических наук">Доктор психологических наук</option>
                            <option value="Доктор сельскохозяйственных наук">Доктор сельскохозяйственных наук</option>
                            <option value="Доктор социологических наук">Доктор социологических наук</option>
                            <option value="Доктор теологических наук">Доктор теологических наук</option>
                            <option value="Доктор технических наук">Доктор технических наук</option>
                            <option value="Доктор фармацевтических наук">Доктор фармацевтических наук</option>
                            <option value="Доктор физико-математических наук">Доктор физико-математических наук</option>
                            <option value="Доктор филологических наук">Доктор филологических наук</option>
                            <option value="Доктор философских наук">Доктор философских наук</option>
                            <option value="Доктор химических наук">Доктор химических наук</option>
                            <option value="Доктор экономических наук">Доктор экономических наук</option>
                            <option value="Доктор юридических наук">Доктор юридических наук</option>
                        </select>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="science_degree"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.science_degree}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="academic_status">Статус РАН, РАО, РАЕ, РАЕН</label>
                        <select id="academic_status" className="form-control" defaultValue={userStatementViewModel.academic_status} disabled>
                            <option value="">- не выбрано</option>
                            <option value="Не состою">Не состою</option>
                            <option value="РАН">РАН</option>
                            <option value="РАО">РАО</option>
                            <option value="РАЕ">РАЕ</option>
                            <option value="РАЕН">РАЕН</option>
                        </select>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="academic_status"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.academic_status}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Другое</label>
                        <p>{userStatementViewModel.academic_status_another}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="academic_status_another"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.academic_status_another}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Наличие отраслевых наград, почетных званий и других отличий</label>
                        <p>{userStatementViewModel.academic_rewards}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="academic_rewards"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.academic_rewards}
                    />
                </div>

            </div>


            <div className={"statement-page " + (statementPage === 3 ? "active" : "")}>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Прохождение курсов, стажировок, присвоение степени или звания и других мероприятий повышения квалификации за последние 3 года</label>
                        <p>{userStatementViewModel.another_courses_and_other}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="another_courses_and_other"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.another_courses_and_other}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Занимаемые должности вне образовательных организаций</label>
                        <p>{userStatementViewModel.positions_outside_education}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="positions_outside_education"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.positions_outside_education}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Общий трудовой стаж, лет </label>
                        <p>{userStatementViewModel.seniority}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="seniority"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.seniority}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Стаж научно-педагогической работы, лет</label>
                        <p>{userStatementViewModel.pedagogical_experience}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="pedagogical_experience"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.pedagogical_experience}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Членство в некоммерческих организациях и объединениях</label>
                        <p>{userStatementViewModel.noncomerce_membership}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="noncomerce_membership"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.noncomerce_membership}
                    />
                </div>
            </div>


            <div className={"statement-page " + (statementPage === 4 ? "active" : "")}>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Являюсь экспертом (указать организацию и профиль)</label>
                        <p>{userStatementViewModel.expert_of}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="expert_of"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.expert_of}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Участие в научно-исследовательском коллективе при проведении НИР/НИОКР (год, название работы, организация)</label>
                        <p>{userStatementViewModel.nir_niokr_membership}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="nir_niokr_membership"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.nir_niokr_membership}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Наличие патентов и авторских свидетельств (номер, дата выдачи, название)</label>
                        <p>{userStatementViewModel.patents_and_copyrights}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="patents_and_copyrights"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.patents_and_copyrights}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Участие во всероссийских и международных конференциях за последние 3 года</label>
                        <p>{userStatementViewModel.meetings_memberships_3_years}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="meetings_memberships_3_years"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.meetings_memberships_3_years}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Количество научных публикаций в ведущих рецензируемых журналах, входящих в список ВАК (шт. всего / в том числе за последние 3 года)</label>
                        <p>{userStatementViewModel.magazine_publications_count}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="magazine_publications_count"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.magazine_publications_count}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Количество публикаций в РИНЦ</label>
                        <p>{userStatementViewModel.rints_publications_count}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="rints_publications_count"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.rints_publications_count}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Количество цитирований моих работ по РИНЦ</label>
                        <p>{userStatementViewModel.rints_citations_count}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="rints_citations_count"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.rints_citations_count}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Индекс Хирша в РИНЦ</label>
                        <p>{userStatementViewModel.hirsh_index}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="hirsh_index"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.hirsh_index}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Перечень опубликованных учебников и учебных пособий (автор, соавтор, редактор)</label>
                        <p>{userStatementViewModel.published_textbooks_tutorials}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="published_textbooks_tutorials"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.published_textbooks_tutorials}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Разработка нового учебного курса, участие в разработке новой учебной программы (годы, название, краткое описание, результат внедрения) </label>
                        <p>{userStatementViewModel.development_study_courses}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="development_study_courses"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.development_study_courses}
                    />
                </div>
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label>Являюсь разработчиком проектов, направленных на развитие и повышение качества высшего образования (годы, название, краткое описание, результат внедрения) </label>
                        <p>{userStatementViewModel.development_study_projects}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="development_study_projects"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.development_study_projects}
                    />
                </div>
            </div>


            <div className={"statement-page " + (statementPage === 5 ? "active" : "")}>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="practice_oriented_teaching">Ведение практико-ориентированной преподавательской деятельности
                        </label>
                        <p>{userStatementViewModel.practice_oriented_teaching}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="practice_oriented_teaching"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.practice_oriented_teaching}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="technical_and_presentation_equipment_use_degree">Степень использования технического и презентационного
                        оборудования </label>
                        <p>{userStatementViewModel.technical_and_presentation_equipment_use_degree}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="technical_and_presentation_equipment_use_degree"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.technical_and_presentation_equipment_use_degree}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="internet_and_professional_activities_use_degree">Степень использования сети Интернет в профессиональной
                        деятельности </label>
                        <p>{userStatementViewModel.internet_and_professional_activities_use_degree}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="internet_and_professional_activities_use_degree"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.internet_and_professional_activities_use_degree}
                    />
                </div>
                
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="digital_training_courses">Наличие цифровых учебных курсов, используемых в смешанном или дистанционном образовательном процессе </label>
                        <p>{userStatementViewModel.digital_training_courses}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="digital_training_courses"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.digital_training_courses}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="study_improvement_information_resource">Наличие информационного ресурса, используемого для повышения качества образовательного процесса </label>
                        <p>{userStatementViewModel.study_improvement_information_resource}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="study_improvement_information_resource"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.study_improvement_information_resource}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="innovative_approach_teaching_development">Разработка и внедрение инновационного подхода к преподаванию </label>
                        <p>{userStatementViewModel.innovative_approach_teaching_development}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="innovative_approach_teaching_development"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.innovative_approach_teaching_development}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="interactive_teaching_methods">Использование интерактивных методов обучения вовлечения учащихся в учебный процесс, расширение традиционных преподавательских практик (указать какие) </label>
                        <p>{userStatementViewModel.interactive_teaching_methods}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="interactive_teaching_methods"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.interactive_teaching_methods}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="graduated_masters_under_my_supervision">Количество магистрантов, успешно защитивших магистерскую диссертацию под  моим руководством</label>
                        <p>{userStatementViewModel.graduated_masters_under_my_supervision}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="graduated_masters_under_my_supervision"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.graduated_masters_under_my_supervision}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="graduated_students_under_my_supervision">Количество аспирантов, успешно защитивших кандидатскую диссертацию под моим руководством</label>
                        <p>{userStatementViewModel.graduated_students_under_my_supervision}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="graduated_students_under_my_supervision"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.graduated_students_under_my_supervision}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="graduated_doctoral_under_my_supervision">Количество докторантов, успешно защитивших докторскую диссертацию под моим руководством</label>
                        <p>{userStatementViewModel.graduated_doctoral_under_my_supervision}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="graduated_doctoral_under_my_supervision"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.graduated_doctoral_under_my_supervision}
                    />
                </div>
                
                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="participant_in_international_educational_projects">Участие в международных образовательных проектах </label>
                        <p>{userStatementViewModel.participant_in_international_educational_projects}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="participant_in_international_educational_projects"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.participant_in_international_educational_projects}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="international_partnership_experience">Опыт работы в международных партнерских коллективах </label>
                        <p>{userStatementViewModel.international_partnership_experience}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="international_partnership_experience"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.international_partnership_experience}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="student_scientific_competitive_and_olympiad_movement_participant">Участие в развитии студенческого научного, конкурсного и олимпиадного движения </label>
                        <p>{userStatementViewModel.student_scientific_competitive_and_olympiad_movement_participant}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="student_scientific_competitive_and_olympiad_movement_participant"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.student_scientific_competitive_and_olympiad_movement_participant}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="vocational_guidance_participant">Участие в профессиональной ориентации детей и молодежи </label>
                        <p>{userStatementViewModel.vocational_guidance_participant}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="vocational_guidance_participant"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.vocational_guidance_participant}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="patriotic_projects_experience">Опыт реализации патриотических проектов </label>
                        <p>{userStatementViewModel.patriotic_projects_experience}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="patriotic_projects_experience"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.patriotic_projects_experience}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="educational_activities_participation">Участие в просветительской деятельности </label>
                        <p>{userStatementViewModel.educational_activities_participation}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="educational_activities_participation"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.educational_activities_participation}
                    />
                </div>

            </div>


            <div className={"statement-page " + (statementPage === 6 ? "active" : "")}>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="other_social_public_educational_work">Прочая социальная, общественная, воспитательная работа</label>
                        <p>{userStatementViewModel.other_social_public_educational_work}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="other_social_public_educational_work"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.other_social_public_educational_work}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="pedagogical_dynasty">Являюсь представителем педагогической династии</label>
                        <p>{userStatementViewModel.pedagogical_dynasty}</p>
                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="pedagogical_dynasty"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.pedagogical_dynasty}
                    />
                </div>


                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="applications_and_characteristics_files">
                            Ходатaйства и характеристики по одному до 40 шт. (Формат JPG, PNG, PDF не более 1МБ каждый)
                        </label> 
                        <br />

                        {list_of_statement_applications_and_characteristics_files}

                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="applications_and_characteristics_files"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.applications_and_characteristics_files}
                    />
                </div>

                <hr /><div className="form-group row">
                    <div className="col-10">
                        <label htmlFor="other_files">Дополнительные файлы по одному до 40 шт. (Формат JPG, PNG, PDF не более 1МБ
                        каждый)</label> <br />

                        {list_of_other_files}

                    </div>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="other_files"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.other_files}
                    />
                </div>

                <hr /><div className="mb-3">
                    <label htmlFor="essay">Эссе (Максимально 5000 символов)</label>
                    <textarea className="form-control " id="essay" maxLength="5000" rows={8} placeholder="" defaultValue={userStatementViewModel.essay} disabled></textarea>
                    <UserExpertStatementGradePointBlock 
                    nameOfValue="essay"
                    formListener={formListener}
                    value={userExpertStatementGradeEditViewModel.essay}
                    />
                </div>

                <hr /><div className="mb-3">
                    <label htmlFor="comment">Комментарий от эксперта</label>
                    <textarea className="form-control" id="comment" name="comment" rows={8} maxLength="5000" placeholder="" defaultValue={userExpertStatementGradeEditViewModel.comment} onChange={formListener}></textarea>
                </div>

            </div>

            <hr />

        </form>
    )
}