import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AdminMiddleware from "../utils/AdminMiddleware";
import StatementService from "../services/StatementService";
import { SystemLoadingBlock } from "../components/SystemLoadingBlock";
import { SystemErrorBlock } from "../components/SystemErrorBlock";
import constant from "../utils/GlobalVariables";
import { Button, Tab, Tabs } from "react-bootstrap";
import UserExpertStatementGradeBlock from "../components/statement/UserExpertStatementGradeBlock";
import ModalNewUserExpertStatementGrade from "../components/statement/ModalNewUserExpertStatementGrade";


export default function StatementPage(props)
{
    const {statement_id} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState();
    const [isSaving, setIsSaving] = useState(false);
    //const [warning, setWarning] = useState("");
    const [nominationMicroViewModels, setNominationMicroViewModels] = useState([]);
    const [userStatementViewModel, setUserStatementViewModel] = useState(null);
    const [isModalNewUserExpertStatementGradeShow, setIsModalNewUserExpertStatementGradeShow] = useState(false);


    const [leagueParticipationMembershipStatusMicroViewModels, setLeagueParticipationMembershipStatusMicroViewModels] = useState([]);
    const [regionMicroViewModels, setRegionMicroViewModels] = useState([]);
    const [universityMicroViewModels, setUniversityMicroViewModels] = useState([]);
    const [userExpertStatementGradeEditViewModels, setUserExpertStatementGradeEditViewModels] = useState([]);

    const [statementPage, setStatementPage] = useState(1);

    

    useEffect(() => {
        const adminMiddleware = new AdminMiddleware();
        const jwt = adminMiddleware.getJWTFromCookie();

        async function get()
        {
            const statementService = new StatementService();
            await statementService.adminGet(
                jwt,
                setIsLoading,
                setIsError,
                statement_id,
                setNominationMicroViewModels,
                setUserStatementViewModel,
                setLeagueParticipationMembershipStatusMicroViewModels,
                setRegionMicroViewModels,
                setUniversityMicroViewModels,
                setUserExpertStatementGradeEditViewModels,
            );
        }
        get();

        return () => {
            //console.log("useEffect empty");
        }
    }, []);

    
    const formListener = (e) => {
        /*
        let copiedUserStatementViewModel = {...userStatementViewModel};
        copiedUserStatementViewModel[e.target.name] = e.target.value;
        setUserStatementViewModel(() => ({
            ...copiedUserStatementViewModel
        }));
        */
    }

    const successfullAddUserExpertStatementGradeCallback = () => {
        window.location.reload();
    }


    if(isLoading)
    {
        return (
            <SystemLoadingBlock />
        )
    }

    if(isError)
    {
        return (
            <SystemErrorBlock />
        )
    }


    const statementsHeaders = [
        "Заявление",
        "Степени и звания",
        "Стаж и дополнительное образование",
        "Общественная и научная активность",
        "Преподавательская деятельность",
        "Прочие данные"
    ];

    const changePage = (value) => {
        if(value)
        {
            if(statementPage >= 6)
            {
                return;
            }
            setStatementPage(statementPage + 1);
        } else 
        {
            if(statementPage === 1)
            {
                return;
            }
            setStatementPage(statementPage - 1);
        }
    }


    const userProfileLiteViewModel = userStatementViewModel.userProfileLiteViewModel;

    let nominationMicroOptions = nominationMicroViewModels.map((nominationMicroViewModel, index) => {
        return <option key={index} value={nominationMicroViewModel.id}>{nominationMicroViewModel.name}</option>
    });

    let hrefToStatementFile = "";
    if(userStatementViewModel.hasOwnProperty("statement_file_src") && userStatementViewModel.statement_file_src !== null && userStatementViewModel.statement_file_src !== "")
    {
        hrefToStatementFile = <span><Link to={constant.baseDomain + "/" + userStatementViewModel.statement_file_src} target="_blank">Загруженное заявление</Link></span>;
    }

    let userPosterImg = "";
    if(userStatementViewModel.hasOwnProperty("photo_file_src") && userStatementViewModel.photo_file_src !== null && userStatementViewModel.photo_file_src !== "")
    {
        userPosterImg = <img className="img-fluid user-poster" src={constant.baseDomain + "/" + userStatementViewModel.photo_file_src} alt="user-poster" />
    }

    
    let list_of_statement_applications_and_characteristics_files = "";
    let statement_applications_and_characteristics_file_index = 1;
    if(userStatementViewModel.userStatementApplicationsAndCharacteristicsFileViewModels !== null)
    {
        list_of_statement_applications_and_characteristics_files = <ul className="files-list">
            {userStatementViewModel.userStatementApplicationsAndCharacteristicsFileViewModels.map(userStatementApplicationsAndCharacteristicsFileViewModel => {
                return <li key={userStatementApplicationsAndCharacteristicsFileViewModel.index}>- <Link to={constant.baseDomain + "/" + userStatementApplicationsAndCharacteristicsFileViewModel.src} target="_blank">
                Файл №{statement_applications_and_characteristics_file_index++}</Link>
                </li>
            })}
        </ul>
    }

    let list_of_other_files = "";
    let other_file_number = 1;
    if(userStatementViewModel.userStatementOtherFileViewModels !== null)
    {
        list_of_other_files = <ul className="files-list">
            {userStatementViewModel.userStatementOtherFileViewModels.map(userStatementOtherFileViewModel => {
                return <li key={userStatementOtherFileViewModel.index}>- <Link to={constant.baseDomain + "/" + userStatementOtherFileViewModel.src} target="_blank">
                Файл №{other_file_number++}</Link>
                </li>;
            })}
        </ul>
    }

    let hrefToProcessDataApplicationFile = "";
    if(userStatementViewModel.hasOwnProperty("process_data_application_file_src") && userStatementViewModel.process_data_application_file_src !== null && userStatementViewModel.process_data_application_file_src !== "")
    {
        hrefToProcessDataApplicationFile = <span><Link to={constant.baseDomain + "/" + userStatementViewModel.process_data_application_file_src} target="_blank">Загруженный файл</Link></span>;
    }

    let userExpertStatementGradeBlocks = "";
    if(userExpertStatementGradeEditViewModels !== null && userExpertStatementGradeEditViewModels.length > 0)
    {
        userExpertStatementGradeBlocks = userExpertStatementGradeEditViewModels.map((userExpertStatementGradeEditViewModel, index) => {

            const isAppreciated = (userExpertStatementGradeEditViewModel.is_appreciated 
                ? " +"
                : " -"
            );

            return <Tab 
            key={index+1}
            eventKey={index+1} title={"Экспертиза " + userExpertStatementGradeEditViewModel.id + isAppreciated}>
                <UserExpertStatementGradeBlock
                    leagueParticipationMembershipStatusMicroViewModels={leagueParticipationMembershipStatusMicroViewModels}
                    regionMicroViewModels={regionMicroViewModels}
                    universityMicroViewModels={universityMicroViewModels}

                    userExpertStatementGradeEditViewModel={userExpertStatementGradeEditViewModel}
                    userStatementViewModel={userStatementViewModel}
                    statementPage={statementPage}
                />
            </Tab>;
        })
    }

    return(
        <div className="page statement-admin">
            <h5>
                Заявление ID {statement_id}
            </h5>
            <p><b>Логин пользователя:</b> {userProfileLiteViewModel.username}</p>
            <p><b>ФИО пользователя:</b> {userProfileLiteViewModel.secondname + " " + userProfileLiteViewModel.firstname + " " + userProfileLiteViewModel.patronymic}</p>
            <p><b>Номинация:</b> {userStatementViewModel.nominationMicroViewModel != null ? userStatementViewModel.nominationMicroViewModel.name : "<не указано>"}</p>
            <p><b>Статус:</b> {userStatementViewModel.status === 1 ? "Отправлена" : "Не отправлена"}</p>
            
            <Button type="button" className="new-statement-grade btn-warning" variant="default"
                onClick={() => setIsModalNewUserExpertStatementGradeShow(true)}
            >
                Назначить экспертизу
            </Button>

            <hr />

            <div className="row statement-control">
                <div className="col-6 col-lg-2 col-md-2 col-sm-3">
                    <Button variant={"info " + (statementPage <= 1 || isSaving ? "disabled" : "")} disabled={statementPage <= 1 ? "disabled" : ""} onClick={() => changePage(false)}>Пред</Button>
                </div>
                <div className="col-2 d-md-none">
                    <Button variant={"info " + (statementPage >= 6 || isSaving ? "disabled" : "")} onClick={() => changePage(true)}>След</Button>
                    <br />
                </div>

                <div className="col-12 col-lg-8 col-md-8 col-sm-6">
                    <p className="statement-page-header">Стр. {statementPage}/6 {statementsHeaders[statementPage-1]}</p>
                </div>
                <div className="col-2 d-none d-md-block">
                    <Button variant={"info " + (statementPage >= 6 || isSaving ? "disabled" : "")} onClick={() => changePage(true)}>След</Button>
                    <br />
                </div>
            </div>

            <Tabs defaultActiveKey={0} id="uncontrolled-tab-example">
                <Tab eventKey={0} title="Заявление">

                    <div className={"statement-page " + (statementPage === 1 ? "active" : "")}>

                        <div className="form-group">
                            <label htmlFor="exampleFormControlFile1">Фотография</label> <br />
                            {userPosterImg}
                        </div>

                        <div className="form-group">
                            <label htmlFor="statement_file">Заявление участника конкурса (Формат JPG, PNG, PDF не более 1МБ)*
                            </label>
                            
                            <br />
                            {hrefToStatementFile}
                            <br />
                        </div>

                        <div className="form-group">
                            <label htmlFor="nomination_id">Номинация*</label>
                            <select id="inputOrganization" name="nomination_id" className="form-control" defaultValue={userStatementViewModel.nomination_id} onChange={formListener}>
                                <option value={0}>Выбрать</option>
                                {nominationMicroOptions}
                            </select>
                        </div>

                    </div>

                    <div className={"statement-page " + (statementPage === 2 ? "active" : "")}>

                        <hr />

                        <div className="form-group">
                            <label>Образование*</label>
                            <input type="text" name="education" className="form-control" defaultValue={userStatementViewModel.education} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="science_degree">Научная степень</label>
                            <select id="science_degree" name="science_degree" className="form-control" defaultValue={userStatementViewModel.science_degree} onChange={formListener}>
                                <option value="">- не выбрано</option>
                                <option value="Не имею">Не имею</option>
                                <option value="Кандидат архитектурных наук">Кандидат архитектурных наук</option>
                                <option value="Кандидат биологических наук">Кандидат биологических наук</option>
                                <option value="Кандидат ветеринарных наук">Кандидат ветеринарных наук</option>
                                <option value="Кандидат военных наук">Кандидат военных наук</option>
                                <option value="Кандидат географических наук">Кандидат географических наук</option>
                                <option value="Кандидат геолого-минералогических наук">Кандидат геолого-минералогических наук
                                </option>
                                <option value="Кандидат искусствоведческих наук">Кандидат искусствоведческих наук</option>
                                <option value="Кандидат исторических наук">Кандидат исторических наук</option>
                                <option value="Кандидат культурологических наук">Кандидат культурологических наук</option>
                                <option value="Кандидат медицинских наук">Кандидат медицинских наук</option>
                                <option value="Кандидат педагогических наук">Кандидат педагогических наук</option>
                                <option value="Кандидат политических наук">Кандидат политических наук</option>
                                <option value="Кандидат психологических наук">Кандидат психологических наук</option>
                                <option value="Кандидат сельскохозяйственных наук">Кандидат сельскохозяйственных наук</option>
                                <option value="Кандидат социологических наук">Кандидат социологических наук</option>
                                <option value="Кандидат теологических наук">Кандидат теологических наук</option>
                                <option value="Кандидат технических наук">Кандидат технических наук</option>
                                <option value="Кандидат фармацевтических наук">Кандидат фармацевтических наук</option>
                                <option value="Кандидат физико-математических наук">Кандидат физико-математических наук</option>
                                <option value="Кандидат филологических наук">Кандидат филологических наук</option>
                                <option value="Кандидат философских наук">Кандидат философских наук</option>
                                <option value="Кандидат химических наук">Кандидат химических наук</option>
                                <option value="Кандидат экономических наук">Кандидат экономических наук</option>
                                <option value="Кандидат юридических наук">Кандидат юридических наук</option>
                                <option value="Доктор архитектурных наук">Доктор архитектурных наук</option>
                                <option value="Доктор биологических наук">Доктор биологических наук</option>
                                <option value="Доктор ветеринарных наук">Доктор ветеринарных наук</option>
                                <option value="Доктор военных наук">Доктор военных наук</option>
                                <option value="Доктор географических наук">Доктор географических наук</option>
                                <option value="Доктор геолого-минералогических наук">Доктор геолого-минералогических наук</option>
                                <option value="Доктор искусствоведческих наук">Доктор искусствоведческих наук</option>
                                <option value="Доктор исторических наук">Доктор исторических наук</option>
                                <option value="Доктор культурологических наук">Доктор культурологических наук</option>
                                <option value="Доктор медицинских наук">Доктор медицинских наук</option>
                                <option value="Доктор педагогических наук">Доктор педагогических наук</option>
                                <option value="Доктор политических наук">Доктор политических наук</option>
                                <option value="Доктор психологических наук">Доктор психологических наук</option>
                                <option value="Доктор сельскохозяйственных наук">Доктор сельскохозяйственных наук</option>
                                <option value="Доктор социологических наук">Доктор социологических наук</option>
                                <option value="Доктор теологических наук">Доктор теологических наук</option>
                                <option value="Доктор технических наук">Доктор технических наук</option>
                                <option value="Доктор фармацевтических наук">Доктор фармацевтических наук</option>
                                <option value="Доктор физико-математических наук">Доктор физико-математических наук</option>
                                <option value="Доктор филологических наук">Доктор филологических наук</option>
                                <option value="Доктор философских наук">Доктор философских наук</option>
                                <option value="Доктор химических наук">Доктор химических наук</option>
                                <option value="Доктор экономических наук">Доктор экономических наук</option>
                                <option value="Доктор юридических наук">Доктор юридических наук</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="academic_status">Статус РАН, РАО, РАЕ, РАЕН</label>
                            <select id="academic_status" name="academic_status" className="form-control" defaultValue={userStatementViewModel.academic_status} onChange={formListener}>
                                <option value="">- не выбрано</option>
                                <option value="Не состою">Не состою</option>
                                <option value="РАН">РАН</option>
                                <option value="РАО">РАО</option>
                                <option value="РАЕ">РАЕ</option>
                                <option value="РАЕН">РАЕН</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Другое</label>
                            <input type="text" name="academic_status_another" className="form-control" defaultValue={userStatementViewModel.academic_status_another} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Наличие отраслевых наград, почетных званий и других отличий</label>
                            <input type="text" name="academic_rewards" className="form-control" defaultValue={userStatementViewModel.academic_rewards} onChange={formListener} />
                        </div>

                    </div>


                    <div className={"statement-page " + (statementPage === 3 ? "active" : "")}>

                        <hr />

                        <div className="form-group">
                            <label>Прохождение курсов, стажировок, присвоение степени или звания и других мероприятий повышения квалификации за последние 3 года</label>
                            <input type="text" name="another_courses_and_other" className="form-control" defaultValue={userStatementViewModel.another_courses_and_other} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Занимаемые должности вне образовательных организаций</label>
                            <input type="text" name="positions_outside_education" className="form-control" defaultValue={userStatementViewModel.positions_outside_education} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Общий трудовой стаж, лет </label>
                            <input type="number" name="seniority" className="form-control" defaultValue={userStatementViewModel.seniority} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Стаж научно-педагогической работы, лет</label>
                            <input type="number" name="pedagogical_experience" className="form-control" defaultValue={userStatementViewModel.pedagogical_experience} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Членство в некоммерческих организациях и объединениях</label>
                            <input type="text" name="noncomerce_membership" className="form-control" defaultValue={userStatementViewModel.noncomerce_membership} onChange={formListener} />
                        </div>
                    </div>


                    <div className={"statement-page " + (statementPage === 4 ? "active" : "")}>

                        <hr />

                        <div className="form-group">
                            <label>Являюсь экспертом (указать организацию и профиль)</label>
                            <input type="text" name="expert_of" className="form-control" defaultValue={userStatementViewModel.expert_of} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Участие в научно-исследовательском коллективе при проведении НИР/НИОКР (год, название работы, организация)</label>
                            <input type="text" name="nir_niokr_membership" className="form-control" defaultValue={userStatementViewModel.nir_niokr_membership} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Наличие патентов и авторских свидетельств (номер, дата выдачи, название) - текстовое поле с неограниченным количеством символов</label>
                            <input type="text" name="patents_and_copyrights" className="form-control" defaultValue={userStatementViewModel.patents_and_copyrights} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Участие во всероссийских и международных конференциях за последние 3 года</label>
                            <input type="text" name="meetings_memberships_3_years" className="form-control" defaultValue={userStatementViewModel.meetings_memberships_3_years} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Количество научных публикаций в ведущих рецензируемых журналах, входящих в список ВАК (шт. всего / в том числе за последние 3 года)</label>
                            <input type="text" name="magazine_publications_count" className="form-control" defaultValue={userStatementViewModel.magazine_publications_count} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Количество публикаций в РИНЦ</label>
                            <input type="text" name="rints_publications_count" className="form-control" defaultValue={userStatementViewModel.rints_publications_count} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Количество цитирований моих работ по РИНЦ</label>
                            <input type="text" name="rints_citations_count" className="form-control" defaultValue={userStatementViewModel.rints_citations_count} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Индекс Хирша в РИНЦ</label>
                            <input type="text" name="hirsh_index" className="form-control" defaultValue={userStatementViewModel.hirsh_index} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Перечень опубликованных учебников и учебных пособий (автор, соавтор, редактор)</label>
                            <input type="text" name="published_textbooks_tutorials" className="form-control" defaultValue={userStatementViewModel.published_textbooks_tutorials} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Разработка нового учебного курса, участие в разработке новой учебной программы (годы, название, краткое описание, результат внедрения) </label>
                            <input type="text" name="development_study_courses" className="form-control" defaultValue={userStatementViewModel.development_study_courses} onChange={formListener} />
                        </div>
                        <div className="form-group">
                            <label>Являюсь разработчиком проектов, направленных на развитие и повышение качества высшего образования (годы, название, краткое описание, результат внедрения) </label>
                            <input type="text" name="development_study_projects" className="form-control" defaultValue={userStatementViewModel.development_study_projects} onChange={formListener} />
                        </div>
                    </div>


                    <div className={"statement-page " + (statementPage === 5 ? "active" : "")}>

                        <hr />

                        <div className="form-group">
                            <label htmlFor="practice_oriented_teaching">Ведение практико-ориентированной преподавательской деятельности
                            </label>
                            <input type="text" className="form-control" id="practice_oriented_teaching" name="practice_oriented_teaching" defaultValue={userStatementViewModel.practice_oriented_teaching} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="technical_and_presentation_equipment_use_degree">Степень использования технического и презентационного
                            оборудования </label>
                            <input type="text" className="form-control" id="technical_and_presentation_equipment_use_degree"
                            name="technical_and_presentation_equipment_use_degree" defaultValue={userStatementViewModel.technical_and_presentation_equipment_use_degree} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="internet_and_professional_activities_use_degree">Степень использования сети Интернет в профессиональной
                            деятельности </label>
                            <input type="text" className="form-control" id="internet_and_professional_activities_use_degree"
                            name="internet_and_professional_activities_use_degree" defaultValue={userStatementViewModel.internet_and_professional_activities_use_degree} onChange={formListener} />
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="digital_training_courses">Наличие цифровых учебных курсов, используемых в смешанном или дистанционном образовательном процессе </label>
                            <input type="text" className="form-control" id="digital_training_courses" name="digital_training_courses" defaultValue={userStatementViewModel.digital_training_courses} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="study_improvement_information_resource">Наличие информационного ресурса, используемого для повышения качества образовательного процесса </label>
                            <input type="text" className="form-control" id="study_improvement_information_resource" name="study_improvement_information_resource" defaultValue={userStatementViewModel.study_improvement_information_resource} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="innovative_approach_teaching_development">Разработка и внедрение инновационного подхода к преподаванию </label>
                            <input type="text" className="form-control" id="innovative_approach_teaching_development" name="innovative_approach_teaching_development" defaultValue={userStatementViewModel.innovative_approach_teaching_development} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="interactive_teaching_methods">Использование интерактивных методов обучения вовлечения учащихся в учебный процесс, расширение традиционных преподавательских практик (указать какие) </label>
                            <input type="text" className="form-control" id="interactive_teaching_methods" name="interactive_teaching_methods" defaultValue={userStatementViewModel.interactive_teaching_methods} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="graduated_masters_under_my_supervision">Количество магистрантов, успешно защитивших магистерскую диссертацию под  моим руководством</label>
                            <input type="number" className="form-control" id="graduated_masters_under_my_supervision" name="graduated_masters_under_my_supervision" defaultValue={userStatementViewModel.graduated_masters_under_my_supervision} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="graduated_students_under_my_supervision">Количество аспирантов, успешно защитивших кандидатскую диссертацию под моим руководством</label>
                            <input type="number" className="form-control" id="graduated_students_under_my_supervision" name="graduated_students_under_my_supervision" defaultValue={userStatementViewModel.graduated_students_under_my_supervision} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="graduated_doctoral_under_my_supervision">Количество докторантов, успешно защитивших докторскую диссертацию под моим руководством</label>
                            <input type="number" className="form-control" id="graduated_doctoral_under_my_supervision" name="graduated_doctoral_under_my_supervision" defaultValue={userStatementViewModel.graduated_doctoral_under_my_supervision} onChange={formListener} />
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="participant_in_international_educational_projects">Участие в международных образовательных проектах </label>
                            <input type="text" className="form-control" id="participant_in_international_educational_projects" name="participant_in_international_educational_projects" defaultValue={userStatementViewModel.participant_in_international_educational_projects} onChange={formListener} />
                        </div>

                            <div className="form-group">
                            <label htmlFor="international_partnership_experience">Опыт работы в международных партнерских коллективах </label>
                            <input type="text" className="form-control" id="international_partnership_experience" name="international_partnership_experience" defaultValue={userStatementViewModel.international_partnership_experience} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="student_scientific_competitive_and_olympiad_movement_participant">Участие в развитии студенческого научного, конкурсного и олимпиадного движения </label>
                            <input type="text" className="form-control" id="student_scientific_competitive_and_olympiad_movement_participant" name="student_scientific_competitive_and_olympiad_movement_participant" defaultValue={userStatementViewModel.student_scientific_competitive_and_olympiad_movement_participant} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="vocational_guidance_participant">Участие в профессиональной ориентации детей и молодежи </label>
                            <input type="text" className="form-control" id="vocational_guidance_participant" name="vocational_guidance_participant" defaultValue={userStatementViewModel.vocational_guidance_participant} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="patriotic_projects_experience">Опыт реализации патриотических проектов </label>
                            <input type="text" className="form-control" id="patriotic_projects_experience" name="patriotic_projects_experience" defaultValue={userStatementViewModel.patriotic_projects_experience} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="educational_activities_participation">Участие в просветительской деятельности </label>
                            <input type="text" className="form-control" id="educational_activities_participation" name="educational_activities_participation" defaultValue={userStatementViewModel.educational_activities_participation} onChange={formListener} />
                        </div>

                    </div>


                    <div className={"statement-page " + (statementPage === 6 ? "active" : "")}>

                        <hr />

                        <div className="form-group">
                            <label htmlFor="other_social_public_educational_work">Прочая социальная, общественная, воспитательная работа</label>
                            <input type="text" className="form-control" id="other_social_public_educational_work" name="other_social_public_educational_work" defaultValue={userStatementViewModel.other_social_public_educational_work} onChange={formListener} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="pedagogical_dynasty">Являюсь представителем педагогической династии</label>
                            <input type="text" className="form-control" id="pedagogical_dynasty" name="pedagogical_dynasty" defaultValue={userStatementViewModel.pedagogical_dynasty} onChange={formListener} />
                        </div>


                        <div className="form-group">
                            <label htmlFor="applications_and_characteristics_files">
                                Ходатaйства и характеристики по одному до 40 шт. (Формат JPG, PNG, PDF не более 1МБ каждый)
                                <br /> 
                            </label> 
                            <br />

                            {list_of_statement_applications_and_characteristics_files}
                        </div>

                        <div className="form-group">
                            <label htmlFor="other_files">Дополнительные файлы по одному до 40 шт. (Формат JPG, PNG, PDF не более 1МБ
                            каждый)</label> <br />

                            {list_of_other_files}
                        </div>

                        <div className="form-group">
                            <label htmlFor="criminal_records">Сведения о судимости и нахождении под арестом*</label>
                            <select id="criminal_records" className="form-control" name="criminal_records" defaultValue={userStatementViewModel.criminal_records} onChange={formListener}>
                                <option value={0}>Выбрать</option>
                                <option value={1}>Не имею</option>
                                <option value={2}>Имею</option>
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="professional_disqualification_records">Сведения о профессиональной дисквалификации*</label>
                            <select id="professional_disqualification_records" className="form-control" name="professional_disqualification_records" defaultValue={userStatementViewModel.professional_disqualification_records} onChange={formListener}>
                                <option value={0}>Выбрать</option>
                                <option value={1}>Не имею</option>
                                <option value={2}>Имею</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="essay">Эссе (Максимально 5000 символов)</label>
                            <textarea className="form-control " id="essay" maxLength="5000" placeholder="" name="essay" defaultValue={userStatementViewModel.essay} onChange={formListener}></textarea>
                        </div>

                        <hr />

                        <div className="form-group">
                            <p>
                            Просим проверить корректность данных, как должна отображаться запись о Вас в Книге Почета
                            преподавателей вузов Российской Федерации в случае Вашей победы в конкурсе: <br />
                            - Фамилия, Имя, Отчество,<br />
                            - Ученая степень и звание,<br />
                            - Полное наименование ВУЗа,<br />
                            - Город.
                            </p>
                        </div>


                        <hr />

                        <div className="form-group">
                            <label htmlFor="process_data_application_file">Заявление на разрешение обработки персональных данных* (Формат JPG, PNG, PDF не более 1МБ)</label> <br />
                            {hrefToProcessDataApplicationFile}
                            <br />
                        </div>

                    </div>

                    <hr />
                    <div className="statement-control">

                    </div>
                </Tab>
                
                {userExpertStatementGradeBlocks}
            </Tabs>

            <ModalNewUserExpertStatementGrade
                isModalNewUserExpertStatementGradeShow={isModalNewUserExpertStatementGradeShow}
                setIsModalNewUserExpertStatementGradeShow={setIsModalNewUserExpertStatementGradeShow}
                statementId={statement_id}
                successfullAddUserExpertStatementGradeCallback={successfullAddUserExpertStatementGradeCallback}
            />
        </div>
    )
}